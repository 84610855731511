import React, {useEffect, useState} from "react";
import Navbar from "./Navbar";
import {AnnouncementIcon, AnnouncementPersonIcon, PDFIcon} from "../../assets/icons";
import Footer from "./Footer";
import {Pagination, Tooltip} from "antd";
import useAxios from "../../hooks/useAxios";
import ErrorModal from "../../shared/components/ErrorModal";
import FullPageLoader from "../../shared/components/FullPageLoader";
import {IAnnouncements} from "../job-settings/types/announcement";
import NoRecordFound from "../../shared/components/NoRecordFound";
import moment from "moment";
import {handleFileDownload} from "../../utils/DownloadFile";
import { useNavigate} from "react-router-dom";

const Announcements = () => {

    const [pageNumber, setPageNumber] = useState(1);

    const {response, error, loading, sendRequest, clearError} = useAxios();

    const pageNumberChangeHandler = (page: number) => {
        setPageNumber(page)
    }

    const getPublishedAnnouncements = async (page: number) => {
        try {
            await sendRequest({
                url: `/public/publishedAnnouncement?pageNumber=${page}`,
                method: "GET",
            });
        } catch (err) {
        }
    };

    useEffect(() => {
        getPublishedAnnouncements(pageNumber);
    }, [pageNumber]);

    return (
        <>
            <div className='flex flex-col min-h-screen bg-announcementBG'>
                <Navbar/>
                <div className='md:container mx-10 md:mx-auto'>
                    <div className='mt-5'>
                        <div className='flex items-center justify-start space-x-1.5'>
                            <h3 className='text-lg font-semibold'>Announcements</h3>
                            <img src={AnnouncementPersonIcon} alt="announcement person icon"/>
                        </div>
                    </div>
                    <div className='mt-5'>
                        {response?.data.length > 0 ? (
                            response?.data?.map((announcement: IAnnouncements) => (
                                <div className='mb-5 flex items-center justify-start bg-white'>
                                    <div
                                        className='hidden md:block border border-l-transparent border-t-transparent border-b-2  px-4 py-5 rounded-l-lg'>
                                        <img src={AnnouncementIcon} alt="announcement icon"/>
                                    </div>
                                    <div
                                        className='border border-t-transparent border-r-transparent border-b-2 p-4 rounded-r-md w-full'>
                                        <div className='flex items-center justify-between'>
                                            <h3 className='text-wrap font-semibold text-sm'>{announcement.title}</h3>
                                            <p className='hidden text-wrap md:block text-xs text-gray-500'>{announcement.description}</p>
                                            <p className='hidden text-wrap md:block text-xs text-gray-500'>Published
                                                on {moment(announcement.updated_at).format('LL')}</p>
                                            <Tooltip title='Download'>
                                                <img
                                                    onClick={
                                                        async () => {
                                                            window.open(`${process.env.REACT_APP_API_BASE_URL}/attachment/download/${announcement.file.id}`,'_blank', '')
                                                            // await handleFileDownload(announcement.file.id, announcement.title, 'attachment/download')
                                                        }
                                                    }
                                                    src={PDFIcon}
                                                    className='cursor-pointer'
                                                    alt="download icon"
                                                />
                                            </Tooltip>
                                        </div>
                                    </div>
                                </div>
                            ))
                        ) : <NoRecordFound/>}
                    </div>
                    <Pagination
                        align='center'
                        defaultPageSize={10}
                        defaultCurrent={1}
                        className='my-10'
                        total={response?.pagination?.total}
                        onChange={pageNumberChangeHandler}
                    />
                </div>
                <div className='mt-auto'>
                    <Footer/>
                </div>
            </div>

            {error && <ErrorModal error={error.error} onClose={clearError}/>}
            {loading && <FullPageLoader/>}
        </>
    )

};

export default Announcements;