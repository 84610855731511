import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import PageButton from "../../shared/components/PageButton";
import PageTitle from "../../shared/components/PageTitle";
import Modal from "../../shared/components/Modal";
import CreateJobRequisition from "./CreateJobRequisition";
import useAxios from "../../hooks/useAxios";
import ErrorModal from "../../shared/components/ErrorModal";
import FullPageLoader from "../../shared/components/FullPageLoader";
import DataTable, {TableColumn} from "react-data-table-component";
import {customTableStyles} from "../../shared/css/customTableStyles";
import NoRecordFound from "../../shared/components/NoRecordFound";
import moment from "moment";
import Actions from "../../shared/components/Actions";
import DropdownMenuItem from "../../shared/components/DropdownMenuItem";
import {AddIcon, ApproveIcon, EditIcon, RejectIcon, RequestApprovalIcon, ViewIcon} from "../../assets/icons";
import {IJobRequisition} from "../../types/JobRequisition";
import {JobRequisitionStatus} from "../../shared/constants/JobRequisition";
import StatusRow from "../../shared/components/StatusRow";
import ExpandedJobRequisition from "./ExpandedJobRequisition";
import EditJobRequisition from "./EditJobRequisition";
import AssignQuestionTemplate from "./AssignQuestionTemplate";
import {Editor} from "react-draft-wysiwyg";
import {QuestionnaireType} from "../../types/questionnaire";

const JobRequisition = () => {

    const [showCreateJobRequisition, setShowCreateJobRequisition] = useState(false)
    const [fetchAgain, setFetchAgain] = useState(false)
    const [editRequisition, setEditRequisition] = useState(false)
    const [requisitionDetails, setRequisitionDetails] = useState<IJobRequisition>()
    const [AddQuestionTemplate, setAddQuestionTemplate] = useState(false)
    const [showViewJobDetails, setShowViewJobDetails] = useState(false)
    const [pageNumber, setPageNumber] = useState(1)

    const navigate = useNavigate()

    const {response, error, loading, sendRequest, clearError} = useAxios()

    const {
        response: approvalRes,
        error: approvalError,
        loading: approvalLoading,
        sendRequest: sendApprovalReq,
        clearError: clearApprovalError
    } = useAxios()

    const columns: TableColumn<IJobRequisition>[] = [
        {
            name: "Created On",
            selector: (row) => moment(new Date(row.created_at)).format("DD-MM-YYYY"),
            sortable: true,
            minWidth: "120px",
        },
        {
            name: "Job Profile",
            selector: (row) =>
                (
                    <span className="text-gray-500">{row.job_profile.title}</span>
                ) as unknown as string,
            wrap: true,
            minWidth: '180px'
        },
        {
            name: "Department",
            selector: (row) =>
                (
                    <span className="text-gray-500">{row.job_profile.organization_structure}</span>
                ) as unknown as string,
            wrap: true,
            minWidth: '180px'
        },
        {
            name: "Deadline",
            selector: (row) => moment(new Date(row.application_deadline)).format("lll"),
            wrap: true,
            sortable: true,
            minWidth: '120px'
        },
        {
            name: "Applications",
            selector: (row) =>
                (
                    <span
                        className="font-semibold">{row.summary.total_applications}</span>
                ) as unknown as string,
            wrap: true,
        },
        {
            name: "Shortlisted",
            selector: (row) =>
                (
                    <span
                        className="font-semibold">{row.summary.shortlisted_applications}</span>
                ) as unknown as string,
            wrap: true,
        },
        {
            name: "Not Selected",
            selector: (row) =>
                (
                    <span
                        className=" font-semibold">{row.summary.not_shortlisted_applications}</span>
                ) as unknown as string,
            wrap: true,
        },
        {
            name: "Status",
            selector: (row) =>
                (
                    <>
                        {
                            row.status === JobRequisitionStatus.OPEN ? (
                                <StatusRow title='OPEN' color='bg-approvedBG'/>
                            ) : row.status === JobRequisitionStatus.DRAFT ? (
                                <StatusRow title='DRAFT' color='bg-blue-400'/>
                            ) : row.status === JobRequisitionStatus.PENDING ? (
                                <StatusRow title='PENDING' color='bg-pendingBG'/>
                            ) : row.status === JobRequisitionStatus.HOLD ? (
                                <StatusRow title='HOLD' color='bg-onHoldBG'/>
                            ) : row.status === JobRequisitionStatus.CLOSED ? (
                                <StatusRow title='CLOSED' color='bg-purpleIconBadge'/>
                            ) : ''
                        }
                    </>
                ) as unknown as string,
        },
        {
            name: "Actions",
            selector: (row) =>
                (
                    <Actions
                        items={[
                            <DropdownMenuItem
                                row={row}
                                handlerFn={() => {
                                    setRequisitionDetails(row)
                                    setShowViewJobDetails(true)
                                }}
                                icon={ViewIcon}
                                label="View Job Details"
                                color="gray-500"
                            />,
                            <DropdownMenuItem
                                row={row}
                                handlerFn={
                                    () => {
                                        const result = row.templates.find(template => template.template.questionnaire_type === QuestionnaireType.SHORTLISTING_QUESTIONS)
                                        navigate('/admin/view-questions', {state: {templateDetails: result?.template}})
                                    }
                                }
                                icon={ViewIcon}
                                label="Shortlisting Questions"
                                color="gray-500"
                            />,
                            (row.status === JobRequisitionStatus.OPEN || row.status === JobRequisitionStatus.CLOSED) && (
                                <DropdownMenuItem
                                    row={row}
                                    handlerFn={() => {
                                        navigate(`/admin/job-requisition/${row.id}/applications`)
                                    }}
                                    icon={ViewIcon}
                                    label="Received Applications"
                                    color="gray-500"
                                />
                            ),
                            row.status === JobRequisitionStatus.DRAFT && (
                                <DropdownMenuItem
                                    row={row}
                                    handlerFn={() => requestApprovalHandler(row.id)}
                                    icon={RequestApprovalIcon}
                                    label="Request Approval"
                                    color="gray-500"
                                />
                            ),
                            row.status === JobRequisitionStatus.PENDING && (
                                <DropdownMenuItem
                                    row={row}
                                    handlerFn={() => approveRequisitionHandler(row.id)}
                                    icon={ApproveIcon}
                                    label="Approve Requisition"
                                    color="gray-500"
                                />
                            ),
                            row.status === JobRequisitionStatus.DRAFT && (
                                <DropdownMenuItem
                                    row={row}
                                    handlerFn={() => {
                                        setRequisitionDetails(row)
                                        setEditRequisition(true)
                                    }}
                                    icon={EditIcon}
                                    label="Edit Requisition"
                                    color="gray-500"
                                />
                            ),
                            row.status === JobRequisitionStatus.DRAFT && (
                                <DropdownMenuItem
                                    row={row}
                                    handlerFn={() => {
                                        setRequisitionDetails(row)
                                        setAddQuestionTemplate(true)
                                    }}
                                    icon={AddIcon}
                                    label="Add Question Template"
                                    color="gray-500"
                                />
                            ),
                            row.status === JobRequisitionStatus.PENDING && (
                                <DropdownMenuItem
                                    row={row}
                                    handlerFn={() => rejectRequisitionHandler(row.id)}
                                    icon={RejectIcon}
                                    label="Reject Requisition"
                                    color="gray-500"
                                />
                            ),
                        ]}
                    />
                ) as unknown as string,
        },
    ];

    const getJobRequisitionsHandler = async (page: number) => {
        try {
            await sendRequest({
                url: `/requisition?pageNumber=${page}`,
                method: "GET",
            });
        } catch (err) {
        }
    };

    const requestApprovalHandler = async (requisitionId: string) => {
        try {
            await sendApprovalReq({
                url: `/requisition/${requisitionId}/requestApproval`,
                method: "PUT",
                data: {},
            });
        } catch (err) {
        }
    }

    const approveRequisitionHandler = async (requisitionId: string) => {
        try {
            await sendApprovalReq({
                url: `/requisition/${requisitionId}/approveRequisition`,
                method: "PUT",
                data: {},
            });
        } catch (err) {
        }
    }

    const rejectRequisitionHandler = async (requisitionId: string) => {
        try {
            await sendApprovalReq({
                url: `/requisition/${requisitionId}/rejectRequisition`,
                method: "PUT",
                data: {},
            });
        } catch (err) {
        }

    }

    const pageNumberChangeHandler = (page: number) => {
        setPageNumber(page)
    }

    useEffect(() => {
        getJobRequisitionsHandler(pageNumber)
    }, [pageNumber]);

    useEffect(() => {
        if (fetchAgain) {
            getJobRequisitionsHandler(pageNumber)
            setFetchAgain(false)
        }
    }, [fetchAgain, pageNumber]);

    useEffect(() => {
        if (approvalRes?.data) {
            setFetchAgain(true);
        }
    }, [approvalRes?.data]);

    return (
        <>
            <PageTitle title='Job Requisitions' dropdown={
                <PageButton
                    permission='CAN_CREATE_REQUISITION'
                    title='Create Job Requisition'
                    onClickHandler={() => {
                        setShowCreateJobRequisition(true)
                    }
                    }
                />
            }
            />
            <div className=''>
                {response?.data?.length > 0 ? (
                    <DataTable
                        columns={columns}
                        data={response?.data}
                        pagination
                        paginationServer
                        paginationPerPage={10}
                        onChangePage={pageNumberChangeHandler}
                        paginationTotalRows={response?.pagination?.total}
                        expandableRows
                        expandOnRowClicked
                        expandableRowsComponent={ExpandedJobRequisition}
                        customStyles={customTableStyles}
                    />
                ) : (
                    <NoRecordFound/>
                )}
            </div>
            {showCreateJobRequisition && (
                <Modal
                    title="Create Job Requisition"
                    content={<CreateJobRequisition onCancel={() => {
                        setShowCreateJobRequisition(false)
                        setFetchAgain(true)
                    }}/>}
                    isShown={showCreateJobRequisition}
                    isHidden={() => setShowCreateJobRequisition(false)}
                    size='large'
                />
            )}
            {editRequisition && requisitionDetails && (
                <Modal
                    title="Edit Job Requisition"
                    content={<EditJobRequisition requisitionDetails={requisitionDetails} onCancel={() => {
                        setEditRequisition(false)
                        setFetchAgain(true)
                    }}
                    />}
                    isShown={editRequisition}
                    isHidden={() => {
                        setRequisitionDetails(undefined)
                        setEditRequisition(false)
                    }}
                    size='large'
                />
            )}
            {(
                AddQuestionTemplate && requisitionDetails && (
                    <Modal
                        title="Assign Question Template"
                        content={<AssignQuestionTemplate requisition={requisitionDetails} onCancel={
                            () => {
                                setRequisitionDetails(undefined)
                                setAddQuestionTemplate(false)
                                setFetchAgain(true)
                            }
                        }/>}
                        isShown={AddQuestionTemplate}
                        isHidden={() => {
                            setRequisitionDetails(undefined)
                            setAddQuestionTemplate(false)
                        }}
                        size='medium'
                    />
                )
            )}
            {showViewJobDetails && requisitionDetails && (
                <Modal
                    title="Job Details"
                    content={
                        <div className='ml-3'>
                            <div className='mb-3'>
                                <h3 className='font-bold text-sm'>Job Description</h3>
                                <div className=''>
                                    <Editor
                                        contentState={JSON.parse(requisitionDetails.job_profile.job_summary)}
                                        readOnly
                                        toolbarHidden
                                        editorClassName="text-xs px-3 rounded"
                                    />
                                </div>
                            </div>
                            <div className='mb-3'>
                                <h3 className='font-bold text-sm'>Job Responsibilities</h3>
                                <div className='ml-2'>
                                    <Editor
                                        contentState={JSON.parse(requisitionDetails.job_profile.job_responsibilities)}
                                        readOnly
                                        toolbarHidden
                                        editorClassName="text-xs px-3 rounded"
                                    />
                                </div>
                            </div>
                            <div className='mb-3'>
                                <h3 className='font-bold text-sm'>Job Requirements</h3>
                                <div className='ml-2'>
                                    <Editor
                                        contentState={JSON.parse(requisitionDetails.job_profile.job_requirements)}
                                        readOnly
                                        toolbarHidden
                                        editorClassName="text-xs px-3 rounded"
                                    />
                                </div>
                            </div>
                        </div>
                    }
                    isShown={showViewJobDetails}
                    isHidden={() => {
                        setRequisitionDetails(undefined)
                        setShowViewJobDetails(false)
                    }}
                    size='large'
                />
            )}
            {error ? <ErrorModal error={error.error} onClose={clearError}/> : approvalError ?
                <ErrorModal error={approvalError.error} onClose={clearApprovalError}/> : ' '}
            {(loading || approvalLoading) && <FullPageLoader/>}
        </>
    )
}

export default JobRequisition;