import React from 'react';
import {Route, Routes} from "react-router-dom";

import {ConfigProvider} from 'antd';
import Landing from "./pages/landing";
import ProtectedLayout from "./shared/layout/AdminLayout";
import Dashboard from "./pages/dashboard/Dashboard";
import Roles from "./pages/roles/Roles";
import {themes} from "./shared/constants/themes";
import JobLevel from "./pages/job-settings/JobLevel";
import KeyCompetency from "./pages/job-settings/KeyCompetency";
import Grade from "./pages/job-settings/Grade";
import Allowance from "./pages/job-settings/Allowance";
import JobQualification from "./pages/job-settings/JobQualification";
import Users from "./pages/users/users";
import {useAuth} from "./hooks/AuthHook";
import {AuthContext} from "./context/AuthContext";
import JobProfile from "./pages/job-profile/JobProfile";

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import JobRequisition from "./pages/job-requisition/JobRequistion";
import JobDescription from "./pages/landing/JobDescription";
import TemplateQuestions from "./pages/template-questions/TemplateQuestions";
import AddTemplateQuestions from "./pages/template-questions/AddTemplateQuestions";
import ViewQuestions from "./pages/template-questions/ViewQuestions";
import Profile from "./pages/candidate-profile/Profile";
import Jobs from "./pages/jobs/Jobs";
import JobDetails from "./pages/jobs/jobDetails";
import MyApplication from "./pages/my-applications/MyApplication";
import Questionnaire from "./pages/jobs/Questionnaire";
import ReceivedApplications from "./pages/job-requisition/ReceivedApplications";
import ApplicationDetails from "./pages/job-requisition/ApplicationDetails";
import Announcements from "./pages/landing/Announcements";
import AnnouncementsManagement from "./pages/job-settings/AnnouncementsManagement";

function App() {
    const {token, login, logout, userId} = useAuth();
    return (
        <AuthContext.Provider
            value={{
                isLoggedIn: !!token,
                token: token,
                userId: userId,
                login: login,
                logout: logout,
            }}
        >
            <ConfigProvider
                theme={themes}>
                <Routes>
                    <Route path="/" element={<Landing/>}/>
                    <Route path="/announcements" element={<Announcements/>}/>
                    <Route path="/job-description/:jobId" element={<JobDescription/>}/>
                    <Route element={<ProtectedLayout/>}>
                        {/*candidate*/}
                        <Route path="/candidate/profile" element={<Profile/>}/>
                        <Route path="/candidate/jobs" element={<Jobs/>}/>
                        <Route path="/candidate/job-details" element={<JobDetails/>}/>
                        <Route path="/candidate/questionnaire" element={<Questionnaire/>}/>
                        <Route path="/candidate/my-applications" element={<MyApplication/>}/>
                        {/*admin*/}
                        <Route path="/admin/dashboard" element={<Dashboard/>}/>
                        <Route path="/admin/roles" element={<Roles/>}/>
                        <Route path="/admin/level" element={<JobLevel/>}/>
                        <Route path="/admin/keyCompetency" element={<KeyCompetency/>}/>
                        <Route path="/admin/grade" element={<Grade/>}/>
                        <Route path="/admin/allowance" element={<Allowance/>}/>
                        <Route path="/admin/qualification" element={<JobQualification/>}/>
                        <Route path="/admin/users" element={<Users/>}/>
                        <Route path="/admin/job-profile" element={<JobProfile/>}/>
                        <Route path="/admin/job-requisition" element={<JobRequisition/>}/>
                        <Route path="/admin/template-questions" element={<TemplateQuestions/>}/>
                        <Route path="/admin/add-template-questions" element={<AddTemplateQuestions/>}/>
                        <Route path="/admin/view-questions" element={<ViewQuestions/>}/>
                        <Route path="/admin/announcements" element={<AnnouncementsManagement/>}/>
                        <Route path="/admin/job-requisition/:requisitionId/applications"
                               element={<ReceivedApplications/>}/>
                        <Route path="/job-requisition/application-details/:applId" element={<ApplicationDetails/>}/>
                    </Route>
                </Routes>
            </ConfigProvider>
        </AuthContext.Provider>
    );
}

export default App;
