import React, {useEffect, useState} from "react";
import moment from "moment";
import {DownloadIcon, FileDeleteIcon, FileIcon, PlusIcon} from "../../assets/icons";
import Modal from "../../shared/components/Modal";
import AddFileAttachment from "./AddFileAttachment";
import NoRecordFound from "../../shared/components/NoRecordFound";
import useAxios from "../../hooks/useAxios";
import ErrorModal from "../../shared/components/ErrorModal";
import FullPageLoader from "../../shared/components/FullPageLoader";
import {Avatar, List, message} from "antd";
import VirtualList from "rc-virtual-list";
import Actions from "../../shared/components/Actions";
import DropdownMenuItem from "../../shared/components/DropdownMenuItem";
import {IFileAttachment} from "../../types/FileAttachment";
import {FileAttachmentTypes} from "../../shared/constants/FileAttachmentTypes";
import {IProfileUser} from "../../types/ProfileUser";
import {getCurrentUserData} from "../../utils/utilities";
import {handleFileDownload} from "../../utils/DownloadFile";

const ContainerHeight = 400;

const FileAttachment = () => {

    const [showAddFile, setShowAddFile] = React.useState<boolean>(false);
    const [fetchAgain, setFetchAgain] = React.useState<boolean>(false);
    const [data, setData] = useState<any[]>([]);

    const userData: IProfileUser = getCurrentUserData();

    const {response, error, loading, sendRequest, clearError} = useAxios();

    const {
        error: fileError,
        loading: fileLoading,
        sendRequest: sendDeleteFileReq,
        clearError: clearFileError
    } = useAxios();

    const handleDeleteFile = async (id: string) => {
        try {
            await sendDeleteFileReq({
                url: `/attachment/remove/${id}`,
                method: "PUT",
            });
        } catch (err) {
        }
    }

    const getFileAttachmentHandler = async () => {
        try {
            await sendRequest({
                url: `/attachment?candidate=${userData.userId.id}`,
                method: "GET",
            });
        } catch (err) {
        }
    }

    useEffect(() => {
        getFileAttachmentHandler();
    }, []);

    useEffect(() => {
        if (fetchAgain) {
            getFileAttachmentHandler();
            setFetchAgain(false);
        }
    }, [fetchAgain]);

    useEffect(() => {
        if (response?.data?.length > 0) {
            setData(response?.data);
            message.success(`${response?.data?.length} file loaded!`);
        }
    }, [response?.data]);

    const onScroll = (e: React.UIEvent<HTMLElement, UIEvent>) => {
        // Refer to: https://developer.mozilla.org/en-US/docs/Web/API/Element/scrollHeight#problems_and_solutions
        if (
            Math.abs(
                e.currentTarget.scrollHeight -
                e.currentTarget.scrollTop -
                ContainerHeight,
            ) <= 1
        ) {
            getFileAttachmentHandler();
        }
    };

    return (
        <>
            <div className='flex justify-end'>
                <button
                    className='flex items-center rounded  text-white border bg-sidebarBG border-sidebarBG space-x-2 px-3 py-1.5 text-xs font-semibold  font-Montserrat disabled:text-white disabled:cursor-not-allowed disabled:shadow-none disabled:opacity-50'
                    onClick={() => setShowAddFile(true)} type='button'>
                    <img src={PlusIcon} alt="plus icon"/>
                    <span>Add New Document</span>
                </button>
            </div>
            <div className=''>
                {response?.data?.length > 0 ? (
                    <List className=''>
                        <VirtualList
                            data={data}
                            height={ContainerHeight}
                            itemHeight={47}
                            itemKey="id"
                            onScroll={onScroll}
                        >
                            {(item: IFileAttachment) => (
                                <List.Item key={item.id}>
                                    <List.Item.Meta
                                        avatar={<Avatar src={FileIcon}/>}
                                        title={
                                            <span className="font-Montserrat text-gray-700 font-semibold">
                                                {item.title}
                                            </span>
                                        }
                                        description={
                                            <span className="font-Montserrat text-gray-500 text-xs">
                                                {FileAttachmentTypes[item.attachment_type].split('_').join(' ') + " uploaded on " + moment(item.created_at).format("LL")}
                                            </span>
                                        }
                                    />
                                    <div>
                                        <Actions
                                            items={[
                                                <DropdownMenuItem
                                                    handlerFn={
                                                        async () => {
                                                            window.open(`${process.env.REACT_APP_API_BASE_URL}/attachment/download/${item.attachment}`,'_blank', '')
                                                            // await handleFileDownload(item.attachment, item.title, 'attachment/download')
                                                        }
                                                    }
                                                    icon={DownloadIcon}
                                                    label="Download Document"
                                                    color="gray-500"
                                                />,
                                                <DropdownMenuItem
                                                    handlerFn={async () => {
                                                        await handleDeleteFile(item.id);
                                                        message.success("Document deleted successfully!");
                                                        setFetchAgain(true);
                                                    }}
                                                    icon={FileDeleteIcon}
                                                    label="Delete Document"
                                                    color="gray-500"
                                                />,
                                            ]}
                                        />
                                    </div>
                                </List.Item>
                            )}
                        </VirtualList>
                    </List>
                ) : (
                    <NoRecordFound WidthSize='w-48' textSize='medium'/>
                )}
            </div>
            {showAddFile && (
                <Modal
                    title="Add New File"
                    content={<AddFileAttachment onCancel={() => {
                        setShowAddFile(false)
                        setFetchAgain(true)
                    }}/>}
                    isShown={showAddFile}
                    isHidden={() => setShowAddFile(false)}
                    size="medium"
                />
            )}
            {error ? <ErrorModal error={error.error} onClose={clearError}/> : fileError ?
                <ErrorModal error={fileError.error} onClose={clearFileError}/> : ''}
            {(loading || fileLoading) && <FullPageLoader/>}
        </>
    )
}

export default FileAttachment;