export const themes = {
    token: {
        fontFamily: 'Montserrat'
    },
    components:
        {
            Carousel:
                {colorBgContainer: 'black'},
            Select: {
                colorPrimary: "#81632D",
                optionActiveBg: "#f2f2f2",
                colorPrimaryHover: "#81632D",
                optionSelectedBg: "#F9F1E3",
                controlOutline: "#FDFBF6",
            },
            Dropdown: {
                colorBgElevated: "#FFFFFF",
                colorText: "#81632D",
            },
            TreeSelect: {
                nodeSelectedBg: "#F9F1E3",
            },
            Radio: {
                colorPrimary: "#81632D",
            },
            Checkbox: {
                colorPrimary: "#81632D",
                colorPrimaryHover: "#81632D",
            },
            Steps: {
                colorPrimary: "#81632D",
                colorPrimaryBorder: "#81632D",
            },
            Upload: {
                colorTextDescription: "#81632D",
                colorText: "#81632D",
            },
            Switch: {
                colorPrimary: "#81632D",
                colorPrimaryHover: "#81632D",

            },
            Pagination: {
                colorPrimary: "#763A18",
                colorPrimaryHover: "#763A18",
            }
        }
}