import React, {useState} from "react";
import {IAnnouncements} from "./types/announcement";
import Button from "../../shared/components/Button";
import ErrorModal from "../../shared/components/ErrorModal";
import * as yup from "yup";
import {IProfileUser} from "../../types/ProfileUser";
import useAxios from "../../hooks/useAxios";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";

interface IProps {
    onCancel: () => void
    details: IAnnouncements
}

interface IFormData {
    title: string;
    description: string;
}

const schema = yup
    .object({
        title: yup.string().required('Title is required'),
        description: yup.string().required('Description is required'),
    })
    .required();

const EditAnnouncement = ({onCancel, details}: IProps) => {

    const {response, error, loading, sendRequest, clearError} = useAxios();

    const {
        register,
        formState: {errors},
        handleSubmit,
    } = useForm<IFormData>({
        resolver: yupResolver(schema),
    });

    const onSubmit = async (data: IFormData) => {
        try {
            const payload = {
                title: data.title,
                description: data.description,
                file: details.file.id,
            };
            await sendRequest({
                url: `/announcements/${details.id}`,
                method: "PUT",
                data: payload,
            });
        } catch (err) {
        }
    }

    if (response?.data) {
        onCancel()
    }

    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="mb-3">
                    <label
                        htmlFor="name"
                        className="block font-Montserrat text-sm font-medium leading-6 text-gray-500"
                    >
                        Title:
                    </label>
                    <div className="mt-2">
                        <input
                            {...register("title")}
                            type="text"
                            defaultValue={details.title}
                            name="title"
                            placeholder='Type here'
                            id="title"
                            className={`pl-2 block w-full rounded ${errors.title && "border-red-500"} border-2 py-0.5 text-gray-600 sm:text-sm sm:leading-6 focus:outline-none`}
                        />
                        <p className="text-red-500 text-xs">{errors.title?.message}</p>
                    </div>
                </div>
                <div className="mb-3">
                    <label
                        htmlFor="description"
                        className="block text-sm font-Montserrat font-medium leading-6 text-gray-500"
                    >
                        Description:
                    </label>
                    <div className="mt-2">
                        <textarea
                            {...register("description")}
                            id="description"
                            name="description"
                            placeholder='Type here'
                            rows={3}
                            className={`pl-2 block w-full ${errors.description && "border-red-500"} rounded-md border-2 py-1.5 text-gray-600 shadow-sm sm:text-sm sm:leading-6 focus:outline-none`}
                            defaultValue={details.description}
                        />
                        <p className="text-red-500 text-xs">{errors.description?.message}</p>
                    </div>
                </div>
                <div className="my-5 border-b"></div>
                <div className="mt-6 flex items-center justify-end gap-x-6">
                    <button
                        type="button"
                        onClick={onCancel}
                        className="text-sm bg-dropdownBG px-3 py-0.5 rounded font-semibold font-Montserrat leading-6 text-black"
                    >
                        Cancel
                    </button>
                    <Button type="submit" btnText="Save Changes" loading={loading}/>
                </div>
            </form>
            {
                error && <ErrorModal error={error.error} onClose={clearError}/>
            }
        </>
    )
}

export default EditAnnouncement