import React from "react";
import {PlusIcon} from "../../assets/icons";
import {Permissions} from "../../utils/utilities";
import {UserType} from "../../types/ProfileUser";

interface IProps {
    onClickHandler: () => void
    title: string
    permission?: string
}

const PageButton = ({onClickHandler, title, permission}: IProps) => {
    return (
        <>
            {permission ? (
                <Permissions privilege={permission} userType={UserType.ADMIN}>
                    <button
                        className="flex items-center justify-center border border-transparent rounded bg-sidebarBG py-1 px-3 space-x-1"
                        onClick={onClickHandler}
                    >
                        <img src={PlusIcon} alt="plus icon"/>{" "}
                        <span className="font-semibold text-white">{" "}{title}</span>
                    </button>
                </Permissions>
            ) : (
                <button
                    className="flex items-center justify-center border border-transparent rounded bg-sidebarBG py-1 px-3 space-x-1"
                    onClick={onClickHandler}
                >
                    <img src={PlusIcon} alt="plus icon"/>{" "}
                    <span className="font-semibold text-white">{" "}{title}</span>
                </button>
            )}
        </>
    )
}

export default PageButton