import React, {useContext, useEffect, useState} from 'react'
import * as yup from "yup";
import useAxios from "../../hooks/useAxios";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import Button from "../../shared/components/Button";
import ErrorModal from "../../shared/components/ErrorModal";
import {useNavigate} from "react-router-dom";
import {AuthContext} from "../../context/AuthContext";
import FullPageLoader from "../../shared/components/FullPageLoader";
import Details from "./Details";
import OtpVerification from "./OtpVerification";
import SetPassword from "./SetPassword";
import {message, Spin, Steps} from 'antd';
import "../../shared/css/custom-steps.css";
import {NidaDetails} from "../../types/NidaDetails";
import {boolean} from "yup";
import {EmailIcon, IdIcon, PhoneIcon, VerifiedIcon} from "../../assets/icons";
import {LoadingOutlined} from "@ant-design/icons";

interface IFormData {
    // nid: string;
    email: string;
    phoneNumber: string;
    password: string;
    rePassword: string;
}

interface IProps {
    setOpenRegisterModal: (arg: boolean) => void;
}


export interface CustomErrors {
    nid?: string;
    email?: string;
    phoneNumber?: string;
}


const schema = yup
    .object({
        // nid: yup.string().length(16, "Invalid NID").required("NID is required"),
        email: yup.string().email("Invalid Email").required("Email is required"),
        phoneNumber: yup.string().length(10, "Invalid phone number , it should have 10 digits").required("Phone number is required"),
        password: yup.string().required("Password is required"),
        rePassword: yup.string().required("Please re-enter password").oneOf([yup.ref('password')], 'Passwords must match')
    })
    .required();

const Registration = ({setOpenRegisterModal}: IProps) => {

    const [otp, setOtp] = useState<string>("");
    const [nid, setNid] = useState<string|undefined>("");
    const [isEmailVerified, setIsEmailVerified] = useState<boolean>(false);
    const {response, error, loading, sendRequest, clearError} = useAxios();
    const navigate = useNavigate();
    const auth = useContext(AuthContext);


    // send otp
    const {
        response: otpRes,
        error: otpError,
        loading: otpLoading,
        sendRequest: otpReq,
        clearError: clearOtpError
    } = useAxios();

    const sendOtpHandler = async (email: string, phoneNumber: string): Promise<boolean> => {
        try {
            await otpReq({
                url: `/candidate/verifyEmail/${email}/${phoneNumber}`,
                method: "GET",
                data: {},
            });
            return true
        } catch (err) {
            return false
        }
    }

    // validate OTP
    const {
        response: otpValidationRes,
        error: otpValidationError,
        loading: otpValidationLoading,
        sendRequest: otpValidationReq,
        clearError: clearOtpValidationError
    } = useAxios();

    const otpValidationHandler = async (email: string, otpInput: string): Promise<void> => {
        try {
            setOtp(otpInput);
            await otpValidationReq({
                url: `/candidate/validateOtp`,
                method: "PUT",
                data: {
                    otp: otpInput,
                    email
                },
                callBack: otpValidationCallback
            });
            console.log('otpValidationRes', otpValidationRes)
            if (otpValidationRes !== undefined) setIsEmailVerified(true);
        } catch (err) {
            setIsEmailVerified(false);
        }
    }

    const otpValidationCallback = (response: Record<string, any>) => {
        if (response.status === 200) setIsEmailVerified(true);
    }

    const {
        register,
        formState: {errors},
        handleSubmit,
        getValues,
        trigger
    } = useForm<IFormData>({
        resolver: yupResolver(schema),
    });


    const onSubmit = async (data: IFormData) => {
        try {
            const payload = {
                nid,
                email: data.email,
                phone_number: data.phoneNumber,
                password: data.password
            };

            await sendRequest({
                url: "/candidate",
                method: "POST",
                data: payload,
            });
        } catch (err) {
        }
    };

    // close model when candidate account created successfully
    if (response?.data) {
        setOpenRegisterModal(false);
    }


    // rag460j

    const next = async (nid?: string) => {
        switch (current) {
            case 0:
                try {
                    setNid(nid);
                    if (await sendOtpHandler(getValues('email'), getValues('phoneNumber'))) {
                        setCurrent(current + 1);
                    }
                } catch (error) {
                }
                break;
            case 1:
                if (isEmailVerified) setCurrent(current + 1);
                break;
        }
    };


    const [current, setCurrent] = useState(0);
    const steps = [
        {
            title: 'Details',
            content: <Details next={next} register={register}  errors={errors}/>,
        },
        {
            title: 'Verification',
            content: <OtpVerification otp={otp} otpValidationHandler={otpValidationHandler}
                                      isEmailVerified={isEmailVerified} email={getValues('email')}/>,
        },
        {
            title: 'Password',
            content: <SetPassword register={register} errors={errors}/>,
        },
    ];


    const prev = () => {
        setCurrent(current - 1);
    };

    const items = steps.map((item) => ({key: item.title, title: item.title}));

    return (
        <>
            <form
                onSubmit={handleSubmit(onSubmit)}
            >
                <>
                    <Steps size="small" className={"custom-steps"} current={current} items={items}/>
                    <div>{steps[current].content}</div>
                    {current!==0&&<div className="flex items-center justify-between mt-8">
                        {current > 0 && (
                            <button onClick={() => prev()}
                                    className='text-primary mr-1 font-bold px-3 py-1.5 rounded border border-primary text-sm'>
                                Back
                            </button>
                        )}
                        {current < steps.length - 1 && (
                            <Button btnText={'Next'} isValid={current === 1 ? !isEmailVerified : false} type="button"
                                    onButtonClick={() => next()}/>
                        )}
                        {current === steps.length - 1 && (
                            <Button btnText={'Register'} loading={loading} type="submit"/>
                        )}
                    </div>}

                </>
            </form>
            {response?.data && message.success('Registration completed successfully!')}
            {error && <ErrorModal error={error.message} onClose={clearError}/>}
            {otpValidationError && <ErrorModal error={otpValidationError.error} onClose={clearOtpValidationError}/>}
            {loading && <FullPageLoader/>}
        </>
    )
}

export default Registration