import React, {useEffect} from 'react';
import {Link, Outlet, useNavigate} from "react-router-dom";
import moment from "moment";

import Sidebar from "../components/Sidebar";
import DashboardHeader from "../components/DashboardHeader";
import {IProfileUser, UserType} from "../../types/ProfileUser";
import {
    AdminUsersIcon, ApplicationIcon,
    DashboardIcon, JobIcon,
    JobProfileIcon, ProfileIcon, QuestionIcon,
    ReportIcon,
    RequisitionIcon, SettingsIcon
} from "../../assets/icons";
import {IMenu} from "./types/Menu";

const ProtectedLayout = () => {

    const userData: IProfileUser = JSON.parse(
        localStorage.getItem("userData") as string,
    );

    const navigate = useNavigate();

    /* Sidebar Menus */

    const menus: IMenu[] = [

        // for admin
        {
            name: 'Dashboard',
            icon: DashboardIcon,
            marginExtend: false,
            userType: UserType.ADMIN,
            path: '/admin/dashboard',
            subMenu: []
        },
        {
            name: 'Job Profile',
            icon: JobProfileIcon,
            marginExtend: false,
            userType: UserType.ADMIN,
            path: '/admin/job-profile',
            subMenu: []
        },
        {
            name: 'Job Requisition',
            icon: RequisitionIcon,
            userType: UserType.ADMIN,
            marginExtend: false,
            path: '/admin/job-requisition',
            subMenu: []
        },

        {
            name: 'Users',
            icon: AdminUsersIcon,
            marginExtend: false,
            path: '/admin/users',
            userType: UserType.ADMIN,
            subMenu: [],
            privilege: 'CAN_VIEW_USERS'
        },

        {
            name: 'Report',
            icon: ReportIcon,
            marginExtend: true,
            userType: UserType.ADMIN,
            path: '/admin/dashboard',
            subMenu: []
        },
        {
            name: 'Help & Support',
            icon: QuestionIcon,
            marginExtend: false,
            userType: UserType.ADMIN,
            path: '/admin/dashboard',
            subMenu: []
        },
        {
            name: 'Settings',
            icon: SettingsIcon,
            marginExtend: false,
            userType: UserType.ADMIN,
            path: '',
            subMenu: [
                {
                    label: <Link to="/admin/roles" className='text-xs'>Roles</Link>,
                    key: '0',
                    privilege: 'CAN_VIEW_ROLES'
                },
                // {
                //     label: <Link to="/admin/level" className='text-xs'>Job Level</Link>,
                //     key: '1',
                // },

                // {
                //     label: <Link to="/admin/grade" className='text-xs'>Job Grade</Link>,
                //     key: '2',
                // },

                // {
                //     label: <Link to="/admin/allowance" className='text-xs'>Job Allowance</Link>,
                //     key: '3',
                // },

                // {
                //     label: <Link to="/admin/qualification" className='text-xs'>Job Qualification</Link>,
                //     key: '4',
                // },

                // {
                //     label: <Link to="/admin/keyCompetency" className='text-xs'>Key Competency</Link>,
                //     key: '5',
                // },

                {
                    label: <Link to="/admin/announcements" className='text-xs'>Announcements</Link>,
                    key: '6',
                },
                {
                    label: <Link to="/admin/template-questions" className='text-xs'>Shortlisting Questions</Link>,
                    key: '6',
                }
            ]
        },


        // for candidate
        {
            name: 'My Profile',
            icon: ProfileIcon,
            marginExtend: false,
            userType: UserType.CANDIDATE,
            path: '/candidate/profile',
            subMenu: []
        },
        {
            name: 'Jobs',
            icon: JobIcon,
            marginExtend: false,
            userType: UserType.CANDIDATE,
            path: '/candidate/jobs',
            subMenu: []
        },
        {
            name: 'My Applications',
            icon: ApplicationIcon,
            marginExtend: false,
            userType: UserType.CANDIDATE,
            path: '/candidate/my-applications',
            subMenu: []
        },
    ]

    useEffect(() => {
        if (
            !userData ||
            !userData.token ||
            moment(userData.expiration) < moment()
        ) {
            navigate("/", {replace: true});
        }
    }, [userData]);

    return (
        <>
            <div className='flex'>
                {/*Sidebar*/}
                <Sidebar menus={menus}/>
                <div className='container mx-auto min-h-screen'>
                    {/*Dashboard header*/}
                    <DashboardHeader/>
                    {/*Dashboard content*/}
                    <div className='mx-5 mt-5 mb-10'>
                        <Outlet/>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ProtectedLayout;