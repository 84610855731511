import React, {useEffect, useState} from "react";
import PageButton from "../../shared/components/PageButton";
import PageTitle from "../../shared/components/PageTitle";
import Modal from "../../shared/components/Modal";
import AddAnnouncements from "./AddAnnouncements";
import useAxios from "../../hooks/useAxios";
import DataTable, {TableColumn} from "react-data-table-component";
import {customTableStyles} from "../../shared/css/customTableStyles";
import NoRecordFound from "../../shared/components/NoRecordFound";
import ErrorModal from "../../shared/components/ErrorModal";
import FullPageLoader from "../../shared/components/FullPageLoader";
import {IAnnouncements} from "./types/announcement";
import moment from "moment";
import Actions from "../../shared/components/Actions";
import DropdownMenuItem from "../../shared/components/DropdownMenuItem";
import {ApproveIcon, EditIcon, HideIcon, PDFIcon} from "../../assets/icons";
import {AnnouncementStatus} from "./types/constants";
import {Tooltip} from "antd";
import {handleFileDownload} from "../../utils/DownloadFile";
import EditAnnouncement from "./EditAnnouncement";

const AnnouncementsManagement = () => {

    const [createAnnouncement, setCreateAnnouncement] = useState(false);
    const [fetchAgain, setFetchAgain] = useState(false);
    const [pageNumber, setPageNumber] = useState(1);
    const [editAnnouncement, setEditAnnouncement] = useState(false)
    const [announcementDetails, setAnnouncementDetails] = useState<IAnnouncements>()

    const {response, error, loading, sendRequest, clearError} = useAxios();

    const {
        response: statusRes,
        error: statusError,
        loading: statusLoading,
        sendRequest: sendAnnouncementStatusReq,
        clearError: clearStatusError
    } = useAxios();

    const columns: TableColumn<IAnnouncements>[] = [
        {
            name: "Created On",
            selector: (row) => moment(new Date(row.created_at)).format("DD-MM-YYYY"),
            sortable: true,
        },
        {
            name: "Title",
            selector: (row) =>
                (
                    <span className="text-gray-500">
            {" "}
                        {row.title}{" "}
          </span>
                ) as unknown as string,
            minWidth: "250px",
            wrap: true,
        },
        {
            name: "Description",
            selector: (row) =>
                (
                    <span className="text-gray-500">{row.description}</span>
                ) as unknown as string,
            minWidth: "300px",
            wrap: true,
        },

        {
            name: "Announcement",
            selector: (row) =>
                (
                    <Tooltip title='Download'>
                        <img onClick={
                            async () => {
                                window.open(`${process.env.REACT_APP_API_BASE_URL}/attachment/download/${row.file.id}`,'_blank', '')
                                // await handleFileDownload(row.file.id, row.title, 'attachment/download')
                            }
                        } width={30} src={PDFIcon} className='cursor-pointer' alt="download icon"/>
                    </Tooltip>
                ) as unknown as string,
            wrap: true,
        },

        {
            name: "Status",
            selector: (row) =>
                (
                    <>
                        {row.status === AnnouncementStatus.PUBLISHED ? (
                            <div className='flex items-center justify-center'>
                                <div className='bg-approvedBG rounded-full w-2 h-2 mr-2'></div>
                                <span
                                    className="text-gray-500">PUBLISHED</span>
                            </div>
                        ) : row.status === AnnouncementStatus.DRAFT ? (
                            <div className='flex items-center justify-center'>
                                <div className='bg-blue-400 rounded-full w-2 h-2 mr-2'></div>
                                <span
                                    className="text-gray-500">DRAFT</span>
                            </div>) : row.status === AnnouncementStatus.HIDDEN ? (
                            <div className='flex items-center justify-center'>
                                <div className='bg-onHoldBG rounded-full w-2 h-2 mr-2'></div>
                                <span
                                    className="text-gray-500">HIDDEN</span>
                            </div>
                        ) : ''}
                    </>
                ) as unknown as string,
        },
        {
            name: "Actions",
            selector: (row) =>
                (
                    <Actions
                        items={[
                            row.status !== AnnouncementStatus.PUBLISHED && (
                                <DropdownMenuItem
                                    row={row}
                                    handlerFn={async () => {
                                        await announcementStatusChangeHandler(row.id, 'PUBLISH')
                                    }}
                                    icon={ApproveIcon}
                                    label="Publish"
                                    color="gray-500"
                                />),
                            row.status === AnnouncementStatus.PUBLISHED && (
                                <DropdownMenuItem
                                    row={row}
                                    handlerFn={async () => {
                                        await announcementStatusChangeHandler(row.id, 'HIDE')
                                    }}
                                    icon={HideIcon}
                                    label="Hide Announcement"
                                    color="gray-500"
                                />),
                            <DropdownMenuItem
                                row={row}
                                handlerFn={() => {
                                    setAnnouncementDetails(row)
                                    setEditAnnouncement(true)
                                }}
                                icon={EditIcon}
                                label="Edit Announcement"
                                color="gray-500"
                            />
                        ]}
                    />
                ) as unknown as string,
        },
    ]

    const announcementStatusChangeHandler = async (announcementId: string, action: string) => {

        if (action === 'PUBLISH') {
            try {
                await sendAnnouncementStatusReq({
                    url: `/announcements/status/${announcementId}`,
                    method: 'PUT',
                    data: {
                        status: AnnouncementStatus.PUBLISHED
                    }
                })

            } catch (err) {
            }
        } else if (action === 'HIDE') {
            try {
                await sendAnnouncementStatusReq({
                    url: `/announcements/status/${announcementId}`,
                    method: 'PUT',
                    data: {
                        status: AnnouncementStatus.HIDDEN
                    }
                })

            } catch (err) {
            }
        } else {
            return null
        }
    }

    const getAnnouncementsHandler = async (page: number) => {
        try {
            await sendRequest({
                url: `/announcements?pageNumber=${page}`,
                method: "GET",
            });
        } catch (err) {
        }
    };

    const pageNumberChangeHandler = (page: number) => {
        setPageNumber(page)
    }

    useEffect(() => {
        getAnnouncementsHandler(pageNumber);
    }, [pageNumber]);

    useEffect(() => {
        if (fetchAgain) {
            getAnnouncementsHandler(pageNumber);
            setFetchAgain(false);
        }
    }, [fetchAgain, pageNumber]);

    useEffect(() => {
        if (statusRes?.data) {
            setFetchAgain(true)
        }
    }, [statusRes?.data]);

    return (
        <>
            <PageTitle title='Announcements' dropdown={
                <PageButton
                    title='New Announcement'
                    onClickHandler={() => {
                        setCreateAnnouncement(true);
                    }}
                />
            }
            />
            <div className='p-10'>
                {response?.data?.length > 0 ? (
                    <DataTable
                        columns={columns}
                        data={response?.data}
                        pagination
                        paginationServer
                        paginationPerPage={10}
                        paginationTotalRows={response?.pagination?.total}
                        onChangePage={pageNumberChangeHandler}
                        customStyles={customTableStyles}
                    />
                ) : (
                    <NoRecordFound/>
                )}
            </div>
            {createAnnouncement && (
                <Modal
                    title="Add New Announcement"
                    content={<AddAnnouncements onCancel={() => {
                        setCreateAnnouncement(false)
                        setFetchAgain(true)
                    }}/>}
                    isShown={createAnnouncement}
                    isHidden={() => setCreateAnnouncement(false)}
                    size='medium'
                />
            )}

            {editAnnouncement && announcementDetails && (
                <Modal
                    title="Edit Announcement"
                    content={
                        <EditAnnouncement
                            onCancel={() => {
                                setAnnouncementDetails(undefined)
                                setEditAnnouncement(false)
                                setFetchAgain(true)
                            }}
                            details={announcementDetails}
                        />}
                    isShown={editAnnouncement}
                    isHidden={() => {
                        setAnnouncementDetails(undefined)
                        setEditAnnouncement(false)
                    }}
                    size='medium'
                />
            )
            }

            {
                error ? <ErrorModal error={error.error} onClose={clearError}/> : statusError ?
                    <ErrorModal error={statusError.error} onClose={clearStatusError}/> : ''
            }
            {(loading || statusLoading) && <FullPageLoader/>}
        </>
    );
}

export default AnnouncementsManagement;