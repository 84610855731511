import React, {useState} from "react";
import * as yup from "yup";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import Button from "../../shared/components/Button";
import {message, Upload, UploadProps} from "antd";
import {UploadIcon} from "../../assets/icons";
import {IProfileUser} from "../../types/ProfileUser";
import useAxios from "../../hooks/useAxios";
import ErrorModal from "../../shared/components/ErrorModal";

interface IProps {
    onCancel: () => void
}

interface IFormData {
    title: string;
    description: string;
}

const schema = yup
    .object({
        title: yup.string().required('Title is required'),
        description: yup.string().required('Description is required'),
    })
    .required();

const AddAnnouncements = ({onCancel}: IProps) => {

    const userData: IProfileUser = JSON.parse(
        localStorage.getItem("userData") as string,
    );

    const [fileIDs, setFileIDs] = useState<{
        file: string,
        fileId: string,
        fileName: string,
    }>();

    const {response, error, loading, sendRequest, clearError} = useAxios();

    const {
        error: deleteFileError,
        sendRequest: sendFileDeleteReq,
        clearError: clearFileDeleteError
    } = useAxios();

    const {
        register,
        formState: {errors},
        handleSubmit,
    } = useForm<IFormData>({
        resolver: yupResolver(schema),
    });

    const onSubmit = async (data: IFormData) => {
        try {
            const payload = {
                title: data.title,
                description: data.description,
                file: fileIDs?.fileId,
            };
            await sendRequest({
                url: "/announcements",
                method: "POST",
                data: payload,
            });
        } catch (err) {
        }
    }

    const fileProps: UploadProps = {
        name: 'file',
        accept: '.pdf',
        action: `${process.env.REACT_APP_API_BASE_URL}/attachment/upload`,
        headers: {
            "Authorization": `Bearer ${userData.token}`,
        },
        onChange(info) {
            if (info.file.status === 'done' && info.file.response?.data?.id) {
                setFileIDs({
                    file: info.file.uid,
                    fileId: info.file.response?.data?.id,
                    fileName: info.file.name,
                });
                message.success(`${info.file.name} file uploaded successfully`);
            } else if (info.file.status === 'error') {
                message.error(`${info.file.name} file upload failed.`);
            }
        },
        async onRemove(file) {

            if (fileIDs) {
                try {
                    await sendFileDeleteReq({
                        url: `/attachment/delete/${fileIDs.fileId}`,
                        method: "POST",
                    });
                } catch (err) {
                }

                setFileIDs(undefined);

                message.success('File removed successfully');

            } else {
                message.error('File not found');
            }

        }
    };

    if (response?.data) {
        onCancel()
    }

    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="mb-3">
                    <label
                        htmlFor="name"
                        className="block font-Montserrat text-sm font-medium leading-6 text-gray-500"
                    >
                        Title:
                    </label>
                    <div className="mt-2">
                        <input
                            {...register("title")}
                            type="text"
                            name="title"
                            placeholder='Type here'
                            id="title"
                            className={`pl-2 block w-full rounded ${errors.title && "border-red-500"} border-2 py-0.5 text-gray-600 sm:text-sm sm:leading-6 focus:outline-none`}
                        />
                        <p className="text-red-500 text-xs">{errors.title?.message}</p>
                    </div>
                </div>
                <div className="mb-3">
                    <label
                        htmlFor="description"
                        className="block text-sm font-Montserrat font-medium leading-6 text-gray-500"
                    >
                        Description:
                    </label>
                    <div className="mt-2">
                        <textarea
                            {...register("description")}
                            id="description"
                            name="description"
                            placeholder='Type here'
                            rows={3}
                            className={`pl-2 block w-full ${errors.description && "border-red-500"} rounded-md border-2 py-1.5 text-gray-600 shadow-sm sm:text-sm sm:leading-6 focus:outline-none`}
                            defaultValue={""}
                        />
                        <p className="text-red-500 text-xs">{errors.description?.message}</p>
                    </div>
                </div>
                <div className='mb-3 mt-5 w-1/2'>
                    <label
                        htmlFor="resume"
                        className="block text-sm font-Montserrat font-medium leading-6 text-gray-700"
                    >
                        Announcement Doc <span className='text-xs text-gray-400'>( pdf )</span>
                    </label>
                    <div className="mt-2">
                        <Upload {...fileProps}>
                            <button
                                type='button'
                                disabled={fileIDs !== undefined}
                                className="outline outline-1 outline-offset-0 rounded px-3 py-1 text-xs text-gray-500 flex items-center justify-center space-x-1.5 hover:bg-userBadgeBg hover:text-sidebarBG hover:outline-none">
                                <img src={UploadIcon} alt="upload icon"/>
                                <span>Upload Announcement</span>
                            </button>
                        </Upload>
                    </div>
                </div>
                <div className="my-5 border-b"></div>
                <div className="mt-6 flex items-center justify-end gap-x-6">
                    <button
                        type="button"
                        onClick={onCancel}
                        className="text-sm bg-dropdownBG px-3 py-0.5 rounded font-semibold font-Montserrat leading-6 text-black"
                    >
                        Cancel
                    </button>
                    <Button type="submit" btnText="Save Announcement" loading={loading} isValid={!fileIDs}/>
                </div>
            </form>
            {
                deleteFileError ? <ErrorModal error={deleteFileError.error} onClose={clearFileDeleteError}/> : error ?
                    <ErrorModal error={error.error} onClose={clearError}/> : ''
            }
        </>
    );
}

export default AddAnnouncements;