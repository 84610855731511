import React, {useContext, useEffect, useState} from "react";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import Navbar from "./Navbar";
import {ArrowIcon, JobSearchIcon} from "../../assets/icons";
import Footer from "./Footer";
import {IPublishedJob} from "../../types/PublishedJob";
import {RequisitionType} from "../../shared/constants/JobRequisition";
import moment from "moment/moment";
import {Editor} from "react-draft-wysiwyg";
import {AuthContext} from "../../context/AuthContext";
import {IProfileUser, UserType} from "../../types/ProfileUser";
import Modal from "../../shared/components/Modal";
import Login from "../authentication/Login";
import ForgotPassword from "../authentication/ForgotPassword";
import Registration from "../registration/Registration";
import useAxios from "../../hooks/useAxios";
import ErrorModal from "../../shared/components/ErrorModal";
import FullPageLoader from "../../shared/components/FullPageLoader";

const JobDescription = () => {
    const {jobId} = useParams();
    const [openLoginModal, setOpenLoginModal] = useState(false);
    const [openForgotPasswordModal, setOpenForgotPasswordModal] = useState(false);
    const [openRegisterModal, setOpenRegisterModal] = useState(false);

    const location = useLocation();
    const data: IPublishedJob = location.state;

    const auth = useContext(AuthContext);

    const userData: IProfileUser = JSON.parse(
        localStorage.getItem("userData") as string
    );

    const {response, loading, error, sendRequest, clearError} = useAxios();

    const getJobDetailsHandler = async () => {
        try {
            await sendRequest({
                url: `/public/publishedJob?requisition_id=${jobId}`,
                method: "GET",
            });
        } catch (err) {
        }
    };

    useEffect(() => {
        getJobDetailsHandler();
    }, []);

    const navigate = useNavigate()

    return (
        <>
            <div className='bg-primaryBG'>
                <Navbar/>
                <div className='bg-badgeBG'>
                    <div className='p-6 sm:p-14 flex flex-col items-center justify-center'>
                        <h3 className='text-primary font-bold text-2xl sm:text-3xl mb-3'>{response?.data[0]?.title}</h3>
                        <p className='text-primary text-xs sm:text-sm mb-5'>{response?.data[0]?.organization_structure ? response?.data[0]?.organization_structure.toUpperCase() : ""}</p>
                        <div className='flex items-center justify-center bg-white px-2 py-1 space-x-1.5 rounded'>
                            <span onClick={() => navigate('/')} className='text-primary font-bold text-xs sm:text-sm cursor-pointer'>Home</span>
                            <img src={ArrowIcon} alt="arrow icon"/>
                            <span className='text-primary text-xs sm:text-sm cursor-pointer'>Jobs</span>
                        </div>
                    </div>
                </div>
                <div className='bg-cardBG pt-10 sm:pt-20 pb-10 sm:pb-16 px-6 sm:px-20 lg:px-44'>
                    <div className='flex flex-col lg:flex-row lg:space-x-24'>
                        <div className='max-h-72 border-2 p-5 bg-textWhite border-transparent rounded-md mb-6 lg:mb-0 lg:min-w-96'>
                            <div className='flex items-center justify-between'>
                                <div className='bg-gray-500 text-textWhite font-bold px-3 py-1 rounded text-xs'>
                                    Featured
                                </div>
                                <div className='bg-badgeBG text-primary font-bold px-3 py-1 rounded text-xs'>
                                    {response?.data[0]?.requisition_type === RequisitionType.CONTRACT ? 'CONTRACT' : response?.data[0]?.requisition_type === RequisitionType.PERMANENT ? 'PERMANENT' : response?.data[0]?.requisition_type === RequisitionType.INTERNSHIP ? 'INTERNSHIP' : response?.data[0]?.requisition_type === RequisitionType.REGULAR ? 'REGULAR' : response?.data[0]?.requisition_type === RequisitionType.TEMPORARY ? 'TEMPORARY' : response?.data[0]?.requisition_type === RequisitionType.LIMITED_TERM ? 'LIMITED TERM' : ' '}
                                </div>
                            </div>
                            <div className='flex items-center justify-center flex-col'>
                                <img src={JobSearchIcon} alt="test exam icon" className="max-w-full"/>
                                <h3 className='text-primary text-center font-bold text-lg mt-5'>
                                    {response?.data[0]?.title}
                                </h3>
                                <div className='mt-5 flex flex-col sm:flex-row items-center justify-center space-y-2 sm:space-y-0 sm:space-x-2'>
                                    <h3 className='text-gray-700 font-semibold underline'>Deadline:</h3>
                                    <p className='text-gray-500 text-sm text-center'>{moment(response?.data[0]?.application_deadline).format("llll")}</p>
                                </div>
                                <div className='mt-5'>
                                    <button
                                        onClick={(auth.isLoggedIn && (userData.userId.user_type === UserType.CANDIDATE)) ? () => navigate('/candidate/job-details', {state: response?.data[0]}) : () => setOpenLoginModal(true)}
                                        className='bg-primary text-textWhite font-bold px-3 py-2 rounded text-xs'
                                    >
                                        Apply For This Position
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className='max-w-full lg:max-w-xl mt-3'>
                            <div className='mb-3'>
                                <h3 className='font-bold text-primary'>Job Description</h3>
                                <div className=''>
                                    <Editor
                                        contentState={response?.data[0]?.job_summary&&JSON.parse(response?.data[0]?.job_summary)}
                                        readOnly
                                        toolbarHidden
                                        editorClassName="px-3 rounded"
                                    />
                                </div>
                            </div>
                            <div className='mb-3'>
                                <h3 className='font-bold text-primary'>Job Responsibilities</h3>
                                <div className='ml-2'>
                                    <Editor
                                        contentState={response?.data[0]?.job_responsibilities&&JSON.parse(response?.data[0]?.job_responsibilities)}
                                        readOnly
                                        toolbarHidden
                                        editorClassName="px-3 rounded"
                                    />
                                </div>
                            </div>
                            <div>
                                <h3 className='font-bold text-primary'>Job Requirements</h3>
                                <div className='ml-2'>
                                    <Editor
                                        contentState={response?.data[0]?.job_requirements&&JSON.parse(response?.data[0]?.job_requirements)}
                                        readOnly
                                        toolbarHidden
                                        editorClassName="px-3 rounded"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/*Other Related Jobs*/}

                {/*<div className='px-28 py-16'>*/}
                {/*    <h3 className='text-primary font-bold text-3xl mb-3'>Related Job</h3>*/}
                {/*    <p className='text-sm text-primary mb-16'>Departments: Customer Services Project Management*/}
                {/*        Development Design*/}
                {/*        Marketing Accounting*/}
                {/*        / Finance</p>*/}
                {/*    <div className='flex items-center justify-center gap-5 flex-wrap'>*/}
                {/*        <div className='border-2 p-5 bg-cardBG border-transparent rounded-md w-96'>*/}
                {/*            <div className='flex items-center justify-between'>*/}
                {/*                <div*/}
                {/*                    className='bg-gray-500 text-textWhite font-bold px-3 py-1 rounded text-xs'>*/}
                {/*                    Featured*/}
                {/*                </div>*/}
                {/*                <div*/}
                {/*                    className='bg-textWhite text-primary font-bold px-3 py-1 rounded text-xs'>*/}
                {/*                    Permanent*/}
                {/*                </div>*/}
                {/*            </div>*/}
                {/*            <div className='flex items-center justify-center flex-col'>*/}
                {/*                <img src={JobSearchIcon} alt="test exam icon"/>*/}
                {/*                <h3 className='text-primary text-center font-bold text-lg mt-5'>Product Manager</h3>*/}
                {/*                <div className='mt-5 flex items-center justify-center space-x-2'>*/}
                {/*                    <h3 className='text-gray-700 font-semibold underline'>Deadline:</h3>*/}
                {/*                    <p className='text-gray-500 text-sm text-center'>{moment().format("llll")}</p>*/}
                {/*                </div>*/}
                {/*                <div className='mt-5'>*/}
                {/*                    <button*/}
                {/*                        className='bg-primary text-textWhite font-bold px-3 py-2 rounded text-xs'>*/}
                {/*                        View Details*/}
                {/*                    </button>*/}
                {/*                </div>*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*        <div className='border-2 p-5 bg-cardBG border-transparent rounded-md w-96'>*/}
                {/*            <div className='flex items-center justify-between'>*/}
                {/*                <div*/}
                {/*                    className='bg-gray-500 text-textWhite font-bold px-3 py-1 rounded text-xs'>*/}
                {/*                    Featured*/}
                {/*                </div>*/}
                {/*                <div*/}
                {/*                    className='bg-textWhite text-primary font-bold px-3 py-1 rounded text-xs'>*/}
                {/*                    Contract*/}
                {/*                </div>*/}
                {/*            </div>*/}
                {/*            <div className='flex items-center justify-center flex-col'>*/}
                {/*                <img src={JobSearchIcon} alt="test exam icon"/>*/}
                {/*                <h3 className='text-primary text-center font-bold text-lg mt-5'>Software*/}
                {/*                    Engineer</h3>*/}
                {/*                <div className='mt-5 flex items-center justify-center space-x-2'>*/}
                {/*                    <h3 className='text-gray-700 font-semibold underline'>Deadline:</h3>*/}
                {/*                    <p className='text-gray-500 text-sm text-center'>{moment().format("llll")}</p>*/}
                {/*                </div>*/}
                {/*                <div className='mt-5'>*/}
                {/*                    <button*/}
                {/*                        className='bg-primary text-textWhite font-bold px-3 py-2 rounded text-xs'>*/}
                {/*                        View Details*/}
                {/*                    </button>*/}
                {/*                </div>*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*        <div className='border-2 p-5 bg-cardBG border-transparent rounded-md w-96'>*/}
                {/*            <div className='flex items-center justify-between'>*/}
                {/*                <div*/}
                {/*                    className='bg-gray-500 text-textWhite font-bold px-3 py-1 rounded text-xs'>*/}
                {/*                    Featured*/}
                {/*                </div>*/}
                {/*                <div*/}
                {/*                    className='bg-textWhite text-primary font-bold px-3 py-1 rounded text-xs'>*/}
                {/*                    Permanent*/}
                {/*                </div>*/}
                {/*            </div>*/}
                {/*            <div className='flex items-center justify-center flex-col'>*/}
                {/*                <img src={JobSearchIcon} alt="test exam icon"/>*/}
                {/*                <h3 className='text-primary text-center font-bold text-lg mt-5'>Cyber Security*/}
                {/*                    Officer</h3>*/}
                {/*                <div className='mt-5 flex items-center justify-center space-x-2'>*/}
                {/*                    <h3 className='text-gray-700 font-semibold underline'>Deadline:</h3>*/}
                {/*                    <p className='text-gray-500 text-sm text-center'>{moment().format("llll")}</p>*/}
                {/*                </div>*/}
                {/*                <div className='mt-5'>*/}
                {/*                    <button*/}
                {/*                        className='bg-primary text-textWhite font-bold px-3 py-2 rounded text-xs'>*/}
                {/*                        View Details*/}
                {/*                    </button>*/}
                {/*                </div>*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*</div>*/}

                <Footer/>
            </div>

            {/* Login Modal */}

            {openLoginModal && (
                <Modal
                    title="Login as a Candidate"
                    content={
                        <Login
                            isApplying={true}
                            onCancel={() => setOpenLoginModal(false)}
                            openForgotPswdModal={() => {
                                setOpenLoginModal(false)
                                setOpenForgotPasswordModal(true)
                            }}
                            openRegisterModal={() => {
                                setOpenLoginModal(false)
                                setOpenRegisterModal(true)
                            }}
                        />
                    }
                    isShown={openLoginModal}
                    isHidden={() => setOpenLoginModal(false)}
                    size="medium"
                />
            )}

            {/* Forgot Password Modal */}

            {openForgotPasswordModal && (
                <Modal
                    title="Forgot Your Password?"
                    content={<ForgotPassword/>}
                    isShown={openForgotPasswordModal}
                    isHidden={() => setOpenForgotPasswordModal(false)}
                    size="medium"
                />
            )}

            {/* Registration Modal */}

            {openRegisterModal && (
                <Modal
                    title="Candidate Registration"
                    content={<Registration setOpenRegisterModal={setOpenRegisterModal}/>}
                    isShown={openRegisterModal}
                    isHidden={() => setOpenRegisterModal(false)}
                    size="medium"
                />
            )}
            {error && <ErrorModal error={error.error} onClose={clearError}/>}
            {loading && <FullPageLoader/>}
        </>
    )
}

export default JobDescription;